import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
} from '@mui/icons-material';
import {
  PatchflowIcon,
  AutofixIcon,
  CodeReviewIcon,
  TemplateIcon,
  RuleIcon,
} from '../components/Icons';
import { fetchPatchflows, createPatchflow, updatePatchflow, deletePatchflow } from '../services/api';
import { toast } from 'react-toastify';

const PATCHFLOW_TYPES = [
  { value: 'autofix', label: 'Autofix', icon: AutofixIcon },
  { value: 'review', label: 'Code Review', icon: CodeReviewIcon },
];

const TEMPLATES = [
  { value: 'python', label: 'Python Best Practices' },
  { value: 'javascript', label: 'JavaScript Standards' },
  { value: 'security', label: 'Security Checks' },
  { value: 'performance', label: 'Performance Optimization' },
  { value: 'custom', label: 'Custom Template' },
];

function PatchflowDialog({ open, onClose, patchflow, onSave }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: 'autofix',
    template: 'custom',
    rules: '',
    ...patchflow,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {patchflow ? 'Edit Patchflow' : 'Create New Patchflow'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={2}
          />
          <TextField
            fullWidth
            select
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            margin="normal"
          >
            {PATCHFLOW_TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                <Box display="flex" alignItems="center">
                  <type.icon sx={{ mr: 1 }} />
                  {type.label}
                </Box>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            label="Template"
            name="template"
            value={formData.template}
            onChange={handleChange}
            margin="normal"
          >
            {TEMPLATES.map((template) => (
              <MenuItem key={template.value} value={template.value}>
                {template.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Rules"
            name="rules"
            value={formData.rules}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={6}
            placeholder="Enter your patchflow rules here..."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {patchflow ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Patchflows() {
  const [patchflows, setPatchflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPatchflow, setSelectedPatchflow] = useState(null);

  useEffect(() => {
    loadPatchflows();
  }, []);

  const loadPatchflows = async () => {
    try {
      const data = await fetchPatchflows();
      setPatchflows(data);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePatchflow = async (data) => {
    try {
      await createPatchflow(data);
      await loadPatchflows();
      toast.success('Patchflow created successfully');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleUpdatePatchflow = async (data) => {
    try {
      await updatePatchflow(selectedPatchflow.id, data);
      await loadPatchflows();
      toast.success('Patchflow updated successfully');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDeletePatchflow = async (id) => {
    if (window.confirm('Are you sure you want to delete this patchflow?')) {
      try {
        await deletePatchflow(id);
        await loadPatchflows();
        toast.success('Patchflow deleted successfully');
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleCopyPatchflow = async (patchflow) => {
    try {
      const copyData = {
        ...patchflow,
        name: `${patchflow.name} (Copy)`,
        id: undefined,
      };
      await createPatchflow(copyData);
      await loadPatchflows();
      toast.success('Patchflow copied successfully');
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Patchflows</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedPatchflow(null);
            setDialogOpen(true);
          }}
        >
          Create Patchflow
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {patchflows.map((patchflow) => (
          <Grid item xs={12} md={6} lg={4} key={patchflow.id}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <PatchflowIcon sx={{ mr: 1 }} />
                  <Typography variant="h6">
                    {patchflow.name}
                  </Typography>
                </Box>
                <Typography color="text.secondary" gutterBottom>
                  {patchflow.description}
                </Typography>
                <Box mt={2}>
                  <Typography variant="body2" color="text.secondary">
                    Type: {patchflow.type}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Template: {patchflow.template}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Tooltip title="Edit">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedPatchflow(patchflow);
                      setDialogOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                  <IconButton
                    size="small"
                    onClick={() => handleCopyPatchflow(patchflow)}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={() => handleDeletePatchflow(patchflow.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {patchflows.length === 0 && !error && (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No patchflows found
          </Typography>
          <Typography color="text.secondary">
            Create your first patchflow to start automating code improvements
          </Typography>
        </Box>
      )}

      <PatchflowDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedPatchflow(null);
        }}
        patchflow={selectedPatchflow}
        onSave={selectedPatchflow ? handleUpdatePatchflow : handleCreatePatchflow}
      />
    </Box>
  );
}

export default Patchflows;
