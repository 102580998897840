import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Switch,
  CircularProgress,
  Alert,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  GitHub as GitHubIcon,
  Refresh as RefreshIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { GitlabIcon } from '../components/Icons';
import { fetchRepositories, syncRepositories, enableRepository, disableRepository } from '../services/api';
import { toast } from 'react-toastify';

function Repositories() {
  const [repositories, setRepositories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [syncing, setSyncing] = useState({ github: false, gitlab: false });
  const [error, setError] = useState(null);

  useEffect(() => {
    loadRepositories();
  }, []);

  const loadRepositories = async () => {
    try {
      const data = await fetchRepositories();
      setRepositories(data);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSync = async (provider) => {
    setSyncing({ ...syncing, [provider]: true });
    try {
      await syncRepositories(provider);
      await loadRepositories();
      toast.success(`Successfully synced ${provider} repositories`);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSyncing({ ...syncing, [provider]: false });
    }
  };

  const handleToggleRepository = async (repo) => {
    try {
      if (repo.enabled) {
        await disableRepository(repo.id);
      } else {
        await enableRepository(repo.id);
      }
      await loadRepositories();
      toast.success(`Repository ${repo.enabled ? 'disabled' : 'enabled'} successfully`);
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Repositories</Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<GitHubIcon />}
            onClick={() => handleSync('github')}
            disabled={syncing.github}
            sx={{ mr: 2 }}
          >
            {syncing.github ? 'Syncing...' : 'Sync GitHub'}
          </Button>
          <Button
            variant="outlined"
            startIcon={<GitlabIcon />}
            onClick={() => handleSync('gitlab')}
            disabled={syncing.gitlab}
          >
            {syncing.gitlab ? 'Syncing...' : 'Sync GitLab'}
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {repositories.map((repo) => (
          <Grid item xs={12} md={6} lg={4} key={repo.id}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  {repo.provider === 'github' ? <GitHubIcon /> : <GitlabIcon />}
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    {repo.name}
                  </Typography>
                </Box>
                <Typography color="text.secondary" gutterBottom>
                  {repo.description || 'No description available'}
                </Typography>
                <Box mt={2}>
                  <Typography variant="body2" color="text.secondary">
                    Default Branch: {repo.default_branch}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Last Updated: {new Date(repo.updated_at).toLocaleDateString()}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: 'space-between', px: 2 }}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                    Enable Patchwork
                  </Typography>
                  <Switch
                    checked={repo.enabled}
                    onChange={() => handleToggleRepository(repo)}
                    color="primary"
                  />
                </Box>
                <Box>
                  <Tooltip title="Repository Settings">
                    <IconButton
                      size="small"
                      onClick={() => {/* TODO: Implement settings dialog */}}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Refresh Repository">
                    <IconButton
                      size="small"
                      onClick={() => handleSync(repo.provider)}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {repositories.length === 0 && !error && (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            No repositories found
          </Typography>
          <Typography color="text.secondary">
            Click the sync buttons above to import your repositories from GitHub or GitLab
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Repositories;
