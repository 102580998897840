const API_URL = '/api';

// Auth endpoints
export const sendMagicLink = async (email) => {
  const response = await fetch(`${API_URL}/auth/magic-link`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to send magic link');
  }

  return response.json();
};

export const verifyMagicLink = async (token) => {
  const response = await fetch(`${API_URL}/auth/verify?token=${token}`, {
    method: 'GET',
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to verify magic link');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};

export const handleOAuthCallback = async (provider, code) => {
  const response = await fetch(`${API_URL}/auth/oauth/${provider}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code, provider }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'OAuth authentication failed');
  }

  const data = await response.json();
  localStorage.setItem('token', data.access_token);
  return data;
};

// Repository management
export const fetchRepositories = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/repositories`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to fetch repositories');
  }

  return response.json();
};

export const syncRepositories = async (provider) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/repositories/sync/${provider}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to sync repositories');
  }

  return response.json();
};

export const enableRepository = async (repoId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/repositories/${repoId}/enable`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to enable repository');
  }

  return response.json();
};

export const disableRepository = async (repoId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/repositories/${repoId}/disable`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to disable repository');
  }

  return response.json();
};

// Patchflow management
export const fetchPatchflows = async () => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/patchflows`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to fetch patchflows');
  }

  return response.json();
};

export const createPatchflow = async (data) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/patchflows`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to create patchflow');
  }

  return response.json();
};

export const updatePatchflow = async (id, data) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/patchflows/${id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to update patchflow');
  }

  return response.json();
};

export const deletePatchflow = async (id) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/patchflows/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.detail || 'Failed to delete patchflow');
  }

  return response.json();
};

// User management
export const getCurrentUser = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No authentication token found');
  }

  const response = await fetch(`${API_URL}/user/me`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token');
      throw new Error('Authentication token expired');
    }
    const error = await response.json();
    throw new Error(error.detail || 'Failed to get user information');
  }

  return response.json();
};

export const isAuthenticated = () => {
  return !!localStorage.getItem('token');
};

export const logout = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
};
