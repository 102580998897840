import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Divider,
  Link,
  CircularProgress,
  Alert,
} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { styled } from '@mui/material/styles';
import { sendMagicLink, verifyMagicLink, handleOAuthCallback, isAuthenticated } from '../services/api';

const Logo = styled('img')({
  width: '64px',
  height: '64px',
  marginBottom: '2rem',
});

const AuthButton = styled(Button)({
  width: '100%',
  padding: '12px',
  marginBottom: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.5rem',
  border: '1px solid rgba(255, 255, 255, 0.12)',
  '&:hover': {
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
});

const GitlabLogo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"/>
  </svg>
);

function Auth() {
  const [email, setEmail] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect if already authenticated
    if (isAuthenticated()) {
      navigate('/dashboard');
      return;
    }

    // Check for magic link token or OAuth callback
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const code = params.get('code');
    const provider = params.get('provider');

    const handleAuth = async () => {
      setLoading(true);
      setError('');
      try {
        if (token) {
          // Handle magic link verification
          await verifyMagicLink(token);
          navigate('/dashboard');
        } else if (code && provider) {
          // Handle OAuth callback
          await handleOAuthCallback(provider, code);
          navigate('/dashboard');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (token || (code && provider)) {
      handleAuth();
    }
  }, [location, navigate]);

  const handleGithubSignIn = () => {
    const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = `${window.location.origin}/auth/callback`;
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=repo,user&state=github`;
  };

  const handleGitlabSignIn = () => {
    const clientId = process.env.REACT_APP_GITLAB_CLIENT_ID;
    const redirectUri = `${window.location.origin}/auth/callback`;
    window.location.href = `https://gitlab.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=api&state=gitlab`;
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await sendMagicLink(email);
      setSuccess('Magic link sent! Check your email.');
      setShowEmailInput(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            Please wait...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
        }}
      >
        <Logo src="/logo.svg" alt="Patchwork" />
        
        {error && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
            {success}
          </Alert>
        )}

        <AuthButton
          variant="outlined"
          onClick={handleGithubSignIn}
          startIcon={<GitHubIcon />}
        >
          Sign in with Github
        </AuthButton>

        <AuthButton
          variant="outlined"
          onClick={handleGitlabSignIn}
          startIcon={<GitlabLogo />}
        >
          Sign in with Gitlab
        </AuthButton>

        <Divider sx={{ width: '100%', my: 2 }}>OR</Divider>

        {!showEmailInput ? (
          <AuthButton
            variant="outlined"
            onClick={() => setShowEmailInput(true)}
          >
            Sign in with Email
          </AuthButton>
        ) : (
          <Box component="form" onSubmit={handleEmailSubmit} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              label="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              type="email"
              required
              placeholder="Your email address"
              disabled={loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Send Magic Link'}
            </Button>
          </Box>
        )}

        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
          By signing up, you agree to our{' '}
          <Link href="/terms" color="primary">
            terms of service
          </Link>{' '}
          and{' '}
          <Link href="/privacy" color="primary">
            privacy policy
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
}

export default Auth;
